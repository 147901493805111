import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Col,
  Container,
  Input,
  FormGroup,
  Card,
  Row,
  Button,
  CardBody,
  Form,
} from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { bindActionCreators } from "redux";
import { actionsCreators } from "../../state/index";
import { useSelector, useDispatch } from "react-redux";
import Header from "components/Headers/Header.js";
import Loader from "react-loader-spinner";

export default function Setting() {
  const allSettings = useSelector((state) => state.allAuth.allSettings);
  const isLoading = useSelector((state) => state.allAuth.isLoading);
  const { addToast } = useToasts();
  const [newPoints, setNewPoints] = useState();
  const [cancelled, setCancelled] = useState();
  const [perDollar, setPerDollar] = useState();
  const [minNumber, setMinNumber] = useState();
  const [maxNumber, setMaxNumber] = useState();
  const [socialShare, setSocialShare] = useState();
  const [data, setdata] = useState([]);
  const dispatch = useDispatch();

  const { GET_SETTINGS, POST_SETTINGS } = bindActionCreators(
    actionsCreators,
    dispatch
  );

  useEffect(() => {
    GET_SETTINGS();
  }, []);

  // console.count(["count"]);
  var currencyFormatter = require("currency-formatter");
  var myNumberWithTwoDecimalPlaces = parseFloat(perDollar).toFixed(2);

  useEffect(() => {
    setNewPoints(allSettings?.data?.processingFees);
    setCancelled(allSettings?.data?.points?.cancelled);
    setPerDollar(allSettings?.data?.points?.perDollar);
    setSocialShare(allSettings?.data?.points?.socialShare);
    setMinNumber(allSettings?.data?.betAmount?.min);
    setMaxNumber(allSettings?.data?.betAmount?.max);
    settingmap();
  }, [allSettings?.data?.status]);

  const onChange = (index, text, feild) => {
    if (feild == "reward") {
      var olddata = data;
      olddata[index].reward = text;
      setdata([...olddata]);
    } else if (feild == "points") {
      var olddata = data;
      olddata[index].points = text;
      setdata([...olddata]);
    } else {
      var olddata = data;
      olddata[index].name = text;
      setdata([...olddata]);
    }
  };
  const settingmap = () => {
    if (allSettings?.data?.status) {
      let keys = Object.keys(allSettings?.data?.status);
      let mapdata = keys.map((key) => {
        allSettings.data.status[key]["points"] = key;
        return allSettings?.data?.status[key];
      });
      if (mapdata.length != data.length) {
        setdata(mapdata);
      }
    }
  };

  const addStatus = () => {
    setdata((prev) => [...prev, { points: 0, name: "", reward: "" }]);
  };

  const removeRemove = (index) => {
    setdata((prev) =>
      // Filter out the item with the matching index
      prev.filter((value, i) => i !== index)
    );
  };

  async function postSettings(e) {
    e.preventDefault();
    let statusModify = {};
    let betAmount = {
      min: minNumber,
      max: maxNumber,
    };
    let pointsModify = {
      cancelled: cancelled,
      perDollar: myNumberWithTwoDecimalPlaces,
      socialShare: socialShare,
    };
    data.forEach((item) => {
      statusModify[item.points] = { ...item };
    });
    try {
      let obj = {
        id: allSettings?.data?.id,
        status: statusModify,
        points: pointsModify,
        processingFees: newPoints,
        betAmount: betAmount,
      };
      const settingsData = await POST_SETTINGS(obj);
      if (settingsData.data.isSuccess) {
        addToast(settingsData?.data?.message, {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        addToast(settingsData?.data?.message, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Header />
      {isLoading ? (
        <div className="aling-items-center mt-5">
          <Loader type="Grid" color="#8DBF26" height={60} width={60} />
        </div>
      ) : (
        <Container>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow mt-4">
              <CardBody>
                <Form>
                  <h1 className="heading-small text-muted mb-4">
                    Processing Fees
                  </h1>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label>Processing Fees (Percentage)</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Processing Fees"
                            value={newPoints}
                            // defaultValue={allSettings?.data?.processingFees}
                            type="number"
                            onChange={(e) => setNewPoints(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h1 className="heading-small text-muted mb-4">Points</h1>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label>Cancelled</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Cancelled"
                            value={cancelled}
                            type="number"
                            onChange={(e) => setCancelled(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label>Per Dollar</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Per Dollar"
                            value={perDollar}
                            type="number"
                            onChange={(e) => setPerDollar(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label>Social Share</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Social Share"
                            value={socialShare}
                            type="number"
                            onChange={(e) => setSocialShare(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h1 className="heading-small text-muted mb-4">Bet Amount</h1>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label>Minimum</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Cancelled"
                            value={minNumber}
                            type="number"
                            onChange={(e) => setMinNumber(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label>Maximum</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="Per Dollar"
                            value={maxNumber}
                            type="number"
                            onChange={(e) => setMaxNumber(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {data?.map((row, index) => (
                    <>
                      <h1 key={index} className="heading-small text-muted mb-4">
                        Status
                      </h1>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label>Points</label>
                              <Input
                                className="form-control-alternative"
                                placeholder="Name"
                                defaultValue={row.points}
                                type="number"
                                required
                                onChange={(e) =>
                                  onChange(index, e.target.value, "points")
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label>Name</label>
                              <Input
                                className="form-control-alternative"
                                placeholder="Name"
                                required
                                defaultValue={row.name}
                                type="text"
                                onChange={(e) =>
                                  onChange(index, e.target.value, "name")
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label>Reward</label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={row.reward}
                                placeholder="Reward"
                                required
                                type="number"
                                onChange={(e) =>
                                  onChange(index, e.target.value, "reward")
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <Button
                              className="mr-4 mbtn"
                              color="danger"
                              onClick={() => removeRemove(index)}
                              size="md"
                              type="submit"
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                      </div>
                      <hr className="my-4" />
                    </>
                  ))}
                  <Button
                    className="mr-4 ml-3"
                    color="success"
                    onClick={addStatus}
                    size="md"
                  >
                    Add New Status
                  </Button>

                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <Button
                      className="mr-4"
                      color="success"
                      onClick={(e) => postSettings(e)}
                      type="submit"
                      size="md"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          {/* <Col lg="12" md="12">
          <h2>{data?.title}</h2>
          <p>{data?.body}</p>
        </Col> */}
        </Container>
      )}
    </>
  );
}
