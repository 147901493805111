// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "react-loader-spinner";
import { UserContext } from "../../context/userContext";
import { useToasts } from "react-toast-notifications";
import {
  getFirestore,
  collection,
  getDocs,
  onSnapshot,
} from "firebase/firestore";

const Header = () => {
  const isloading = useSelector((state) => state.allAuth.headerLoading);
  const userData = useSelector((state) => state.allAuth.userData);
  const allGroups = useSelector((state) => state.allAuth.allGroups);
  // const { setUser } = useContext(UserContext);
  // const { addToast } = useToasts();
  const history = useHistory();
  const [userOnline, setUserOnline] = useState(0);

  const db = getFirestore();

  useEffect(() => {
    // Moved inside "useEffect" to avoid re-creating on render
    const handleMagazinesChanges = (snapshot) => {
      const changes = snapshot.docChanges();

      // Accumulate differences
      let difference = 0;
      changes.forEach((change) => {
        snapshot.forEach((data) => {
          const useronlineData = data.data();
          difference = useronlineData?.online;
        });
      });
      // Use the setState callback
      setUserOnline(() => difference);
    };

    // Create the DB listener
    const colRef = collection(db, "counts");
    const unsuscribe = onSnapshot(colRef, handleMagazinesChanges, (err) =>
      console.log(err)
    );
    return () => {
      unsuscribe();
    };
  }, []);

  const date = new Date();
  const month = date.toLocaleString("default", { month: "long" });
  const listItems = userData?.data?.list?.map((row) =>
    moment(new Date(row.createdOnDate)).format("MMMM")
  );
  const monthingroup = allGroups?.data?.list?.map((row) =>
    moment(new Date(row.createdOnDate)).format("MMMM")
  );

  const count = listItems?.filter((e) => e === month);
  const groupMonthcount = monthingroup?.filter((e) => e === month);
  const percenttagemonthTotal =
    (100 * groupMonthcount?.length) / allGroups?.data?.size;
  const percenttagemonth = percenttagemonthTotal.toFixed(2);

  const pushto = () => {
    history.push("/admin/users");
  };
  const pushtogroups = () => {
    history.push("/admin/groups");
  };

  // const colRef = collection(db, "counts");
  // onSnapshot(colRef, (snapshot) => {
  //   let online = [];
  //   snapshot.docs.forEach((doc) => {
  //     online?.push({ ...doc.data(), id: doc.id });
  //   });
  // });

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col lg="6" xl="3">
                <Card
                  onClick={pushto}
                  className="card-stats mb-4 mb-xl-0 userhead"
                >
                  <CardBody>
                    {isloading ? (
                      <div className="col aling-items-center">
                        <Loader
                          type="Grid"
                          color="#8DBF26"
                          height={30}
                          width={30}
                        />
                      </div>
                    ) : (
                      <>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Users
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {userData?.data?.list?.length}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" />
                            {/* {row.createdOnDate} */}
                            {count?.length}
                          </span>
                          <span className="text-nowrap">This month</span>
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card
                  onClick={pushtogroups}
                  className="card-stats mb-4 mb-xl-0 userhead"
                >
                  <CardBody>
                    {isloading ? (
                      <div className="col aling-items-center">
                        <Loader
                          type="Grid"
                          color="#8DBF26"
                          height={30}
                          width={30}
                        />
                      </div>
                    ) : (
                      <>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Groups
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {allGroups?.data?.size}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fas fa-chart-pie" />
                            </div>
                          </Col>
                        </Row>

                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fas fa-arrow-up" /> {percenttagemonth}
                            %
                          </span>{" "}
                          <span className="text-nowrap">Since this Month</span>
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    {isloading ? (
                      <div className="col aling-items-center">
                        <Loader
                          type="Grid"
                          color="#8DBF26"
                          height={30}
                          width={30}
                        />
                      </div>
                    ) : (
                      <>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total User Online
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-success">
                              {userOnline <= 0 ? "0" : userOnline}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-warning mr-2"> </span>{" "}
                          <span className="text-nowrap"></span>
                        </p>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              {/* <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Performance
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">49,65%</span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-success mr-2">
                        <i className="fas fa-arrow-up" /> 12%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
